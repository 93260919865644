<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-input v-model="searchinfo.title"
                  size="mini"
                  placeholder="收件人姓名"
                  label="收件人姓名"
                  clearable></el-input>
        <el-button type="primary" @click="dosearch()"
                   size="mini" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.search') }}
        </el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table ref="multipleTable"
                border
                size="mini"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%">
        <el-table-column
            label="ID"
            prop="id" width="60">
        </el-table-column>
        <el-table-column
            label="会员账号"
            prop="wacc"
            width="120">
        </el-table-column>
        <el-table-column
            label="收票人"
            prop="receiver"
            width="120">
        </el-table-column>
        <el-table-column
            label="联系手机"
            prop="mobile"
            width="120">
        </el-table-column>
        <el-table-column
            label="收票地址">
          <template slot-scope="scope">
            {{ scope.row.province }} {{ scope.row.citys }} {{ scope.row.area }} {{ scope.row.address }}
          </template>
        </el-table-column>
        <el-table-column
            label="是否默认"
            prop="is_default" width="120">
          <template slot-scope="scope">
            <el-tag type="success" size="small" v-if="scope.row.is_default == 1">{{$t('global.enable')}}</el-tag>
            <el-tag type="warning" size="small" v-else>{{ $t('global.disable') }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            :label="$t('grade.created_at')"
            width="180" prop="created_at">
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @size-change="SizeChange"
          @current-change="getlist"
          :current-page="searchinfo.page"
          :page-sizes="PAGE.pageSizes"
          :page-size="searchinfo.pagesize"
          :layout="PAGE.layout"
          :total="totalRows">
      </el-pagination>
    </div>
  </el-main>
</template>

<script>
export default {
  name: 'address',
  data() {
    return {
      searchinfo: {
        page: 1,
        pagesize: this.PAGE.pageSize,
        title: '',
      },
      list: [],
      totalRows: 0,
      defaultuserinfo: {
        id: 0,
        name: '',
        picture: '',
        remarks:'',
        ordid:0,
        status: 1,
      },
      gradeformdata: {},
      rules: {},
      isshowdialog: false,
      isbusy: false,
      uploadurl: process.env.VUE_APP_API_ROOT+'/system/upload/images?storage=custom',
      uploadheader: {Authorization: this.$store.getters.getToken},
    }
  },
  methods: {
    SizeChange(val) {
      this.searchinfo.pagesize = val;
      this.getlist(1);
    },
    editgrade(item) {
      this.gradeformdata = null;
      this.gradeformdata = this.cloneData(item);
      this.isshowdialog = true;
    },
    addgrade(formName) {
      this.gradeformdata = this.cloneData(this.defaultuserinfo);
      this.isshowdialog = true;
      /*this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });*/
    },
    async dosearch() {
      this.getlist(1);
    },
    async getlist(page) {
      this.searchinfo.page = page || this.searchinfo.page;
      this.isbusy = true;
      let res = await this.httpget('/system/address/index', this.searchinfo);
      if (res.code === 0) {
        this.list = [];
        res.data.data.forEach((data) => {
          this.list.push(data);
        })
        this.totalRows = res.data.total;
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }

    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      let res = await this.httppost('/system/address/save', this.gradeformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist(1);
        this.gradeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async setenable(item) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/system/address/setenable', {id: item.id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          let idx = this.list.findIndex((value) => {
            return value.id === item.id
          })
          if (idx !== -1) {
            this.list[idx].status = Math.abs(this.list[idx].status - 1);
          }
          this.isbusy = false;
        } else {
          this.isbusy = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    async deluser(item) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/system/address/delete', {id: item.id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          this.getlist();
        } else {
          this.$message({type: 'error', message: res.msg});
        }
        this.isbusy = false;
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    uploadsuccess(res){
      if(res.code===0){
        if(res.data[0]
            && res.data[0].indexOf(process.env.VUE_APP_IMG_DOMAIN)===-1
            && res.data[0].indexOf('http://')===-1){
          res.data[0] = process.env.VUE_APP_IMG_DOMAIN + '/storage/' + res.data[0];
        }
        this.gradeformdata.picture = res.data[0];
      }else{
        this.$message({type: 'error', message: res.msg});
      }
    },
    beforeupload(file) {
      const isJPG = (file.type === 'image/jpeg'||file.type === 'image/png');
      const isLt2M = file.size / 1024 / 1024 < 8;
      if (!isJPG) {
        this.$message.error('Upload avatar pictures can only be in JPG or PNG format!');
      }
      if (!isLt2M) {
        this.$message.error('Upload avatar image size can\'t exceed 2MB!');
      }
      return isJPG && isLt2M;
    },
  },
  mounted() {
    this.getlist(1);
  },
  computed: {
  },
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.edui-popup-content.edui-default{ height: auto !important; }
.edui-combox-body.edui-default{
  height:25px;

}
.edui-default .edui-toolbar .edui-combox-body .edui-button-body{
  line-height: 25px;
}
.edui-default .edui-toolbar .edui-combox-body .edui-button-body{
  line-height: 25px !important;
}
.edui-default .edui-toolbar .edui-splitborder{
  height:25px !important;
}
.edui-default .edui-toolbar .edui-combox-body .edui-arrow{
  height:25px !important;
  width:13px !important;
}
#edui_fixedlayer{
  z-index:100000 !important;
}
</style>
